<template>
	<nav class="navigation">
		<div v-if="!broker" class="navigation__menu nav-menu">
			<ul class="nav-menu__list">
				<li v-for="item in config.links" :key="item.link">
					<transition name="fade" mode="out-in">
						<router-link
							v-if="$store.state.isLangFileLoaded"
							class="nav-menu__link"
							:class="{ 'nav-menu__link--active': item.link === $route.name }"
							:to="{
								name: item.link,
								params: { lang: $store.state.uiLanguage }
							}"
							rel="noopener noreferrer"
						>
							{{ item.text }}
						</router-link>
						<or-skeleton
							v-else
							class="nav-menu__link"
							style="width: 70px; height: 15px"
						/>
					</transition>
				</li>
			</ul>
		</div>
		<div class="navigation__control-panel">
			<ul
				class="navigation__toggler nav-toggler"
				style="margin-right: 0"
				v-if="config.languages.length > 1"
			>
				<li
					v-for="item in config.languages"
					:key="item"
					class="nav-toggler__item"
				>
					<button
						type="button"
						class="btn nav-toggler__btn"
						:class="{
							'nav-toggler__btn--active': item === $store.state.uiLanguage
						}"
						rel="noopener noreferrer"
						@click="changeLang(item)"
					>
						{{ item.toUpperCase() }}
					</button>
				</li>
			</ul>
			<ul class="navigation__toggler nav-toggler">
				<li v-for="item in config.areas" :key="item">
					<button
						type="button"
						class="btn nav-toggler__btn"
						:class="{
							'nav-toggler__btn--active': item.value === $store.state.areaUnit
						}"
						rel="noopener noreferrer"
						@click="changeActiveMoney(item)"
					>
						{{ item.title }}
					</button>
				</li>
			</ul>
			<div class="navigation__toggler money-list">
				<or-drop-down>
					<template v-slot:toggle="{ active }">
						<button type="button" class="btn money-list__toggle money-toggle">
							<span class="money-toggle__text">
								{{ activeMoney }}
							</span>
							<or-icon
								class="money-toggle__icon"
								:class="{ 'money-toggle__icon--active': active }"
								type="arrow-header"
							/>
						</button>
					</template>
					<template v-slot:content>
						<ul class="money-list__content">
							<li
								v-for="(item, index) in config.money"
								:key="index"
								class="money-list__item"
								:class="{
									'money-list__item--active': item?.slice(0, 3) === activeMoney
								}"
								@click="editMoney(item?.slice(0, 3))"
							>
								{{ item }}
							</li>
						</ul>
					</template>
				</or-drop-down>
			</div>
		</div>
	</nav>
</template>

<script>
import { areaConfig } from '@/enums/areaCollections.js'
import eventBus from '@/plugins/eventBus'

export default {
	props: {
		broker: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		config() {
			return {
				languages: process.env.VUE_APP_ACCEPTED_LANGUAGES?.split(','),
				areas: areaConfig,
				money: ['USD', 'AED', 'AUD', 'CAD', 'EUR', 'GBP', 'RUB', 'CNY'],
				links: [
					{
						link: 'Developers',
						text: this.$t('general.developers')
					},
					{
						link: 'Projects',
						text: this.$t('general.projects')
					},
					{
						link: 'Areas',
						text: this.$t('general.areas')
					},
					{
						link: 'Favourites',
						text: this.$t('general.favorite')
					}
				]
			}
		},
		activeMoney() {
			return this.$store.state.activeMoney
		}
	},
	methods: {
		changeLang(lang) {
			if (lang === this.$store.state.uiLanguage) return
			this.$store.commit('setUiLanguage', lang)
			this.$router
				.push({
					name: this.$route.name,
					params: { lang },
					query: { ...this.$route.query }
				})
				.then(() => window.location.reload())
		},
		changeActiveMoney(item) {
			if (item.value === this.$store.state.areaUnit) return
			this.$store.commit('setAreaUnit', item.value)
		},
		editMoney(item) {
			this.$store.commit('setActiveMoney', item)
			eventBus.trigger('dropdown-hide')
		}
	}
}
</script>

<style lang="scss">
.navigation {
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: 30px;

	&__control-panel {
		display: flex;
		align-items: center;
	}

	&__toggler {
		border-left: 1px solid #e6e6e6;
	}
	@media (max-width: 1025px) {
		gap: 10px;
	}

	@media (max-width: 991px) {
		display: block;
		padding: 20px;
		gap: 0;

		&__menu {
			margin-bottom: 40px;
		}

		&__control-panel {
			justify-content: center;
		}

		&__toggler:first-child {
			border-left: 0;
		}
	}
	@media (max-width: 913px) {
		gap: 5px;
	}
}
</style>

<style lang="scss" scoped>
.nav-menu {
	&__list {
		display: flex;
		gap: 40px;
	}

	&__link {
		color: #808080;
		font-size: 14px;
		line-height: 18px;

		&:hover,
		&--active {
			color: #c99d56;
		}
	}
	@media (max-width: 1200px) {
		&__list {
			gap: 30px;
		}
	}
	@media (max-width: 1025px) {
		&__list {
			gap: 15px;
		}
	}

	@media (max-width: 991px) {
		&__list {
			display: block;

			li {
				width: 100%;
			}
		}

		&__link {
			display: block;
			padding: 15px 0;
		}
	}

	@media (max-width: 913px) {
		&__list {
			gap: 7px;
		}
	}
}
</style>

<style lang="scss">
.nav-toggler {
	display: flex;
	padding: 0 20px;
	gap: 10px;

	&__btn {
		color: #808080;
		font-size: 14px;
		line-height: 18px;

		&:hover,
		&--active {
			color: #c99d56;
		}
	}

	@media (max-width: 991px) {
		padding: 0 30px;
	}
}
</style>

<style lang="scss">
.money-list {
	.money-toggle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 20px;
		gap: 5px;
		cursor: pointer;

		&__text {
			font-style: normal;
			font-size: 14px;
			line-height: 18px;
			color: #c99d56;
		}

		&__icon {
			position: relative;
			display: inline-block;
			width: 24px;
			height: 24px;

			&:before {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: auto;
				transition: all 0.5s linear;
			}

			&--active:before {
				transform: rotate(180deg);
			}
		}
	}

	&__content {
		width: 150px;
		padding: 15px;
		border-radius: 4px;
		background-color: white;
		z-index: 99;
		box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
		position: relative;
		top: 10px;
	}

	&__item {
		text-align: left;
		font-style: normal;
		font-size: 14px;
		line-height: 38px;
		cursor: pointer;
		color: $black-secondary;

		&--active {
			color: #c99d56;
		}
	}

	@media (max-width: 913px) {
		.money-toggle {
			padding-left: 10px;
		}
	}

	@media (max-width: 800px) {
		.money-toggle {
			padding-left: 30px;
		}
	}
}
</style>
